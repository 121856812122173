import { map, find, get } from "lodash";
import * as $ from "jquery";
import "jquery-mask-plugin";
import "select2";
import "simplelightbox";

import "main.scss";

$(() => {
  // window.dataLayer = window.dataLayer || [];
  const selectAddress = $(".select--address");
  const selectDelivery = $(".select--delivery");
  const address = $("#order-form input[name=delivery_address]");
  const product = $("#order-form input[name=product]");
  const tons = $("#order-form input[name=qty]");
  const phone = $("#order-form input[name=phone]");
  const price = $("#order-form input[name=price]");

  const overlay = $(".overlay");
  const orderForm = $("#order-form");

  selectDelivery.select2({ width: "style" });
  selectAddress.select2({ width: "style" });
  $(".docs a").simpleLightbox();

  phone.mask("099 999-99-99", { placeholder: "050 123-45-67" });

  const order = {
    price: parseInt(price.val().toString(), 10),
    qty: parseInt(tons.val().toString(), 10),
    phone: "",
    delivery: 0,
    delivery_address: "",
    delivery_price: 0,
  };

  if (selectAddress.length > 0) {
    let delivery = {};

    $.ajax({
      method: "GET",
      url: "/api/delivery/",
      dataType: "json",
      cache: true,
    }).done((data) => {
      delivery = data;
      const results = map(data, (item) => {
        return { id: item.id, text: item.title };
      });
      selectAddress.select2({ data: results });
    });
    selectAddress.on("select2:select", (e) => {
      const id = parseInt(e.params.data.id, 10);
      order.delivery = id;
      const deliveryItem = find(delivery, ["id", id]);
      order.delivery_price = parseInt(get(deliveryItem, "price", "0"), 10);
      calculate();
    });
  }

  $("#pre-order-btn").on("click", (e) => {
    orderForm.trigger("submit");
    e.preventDefault();
  });
  $(".overlay-box_close").on("click", (e) => {
    overlay.css("display", "none");
    e.preventDefault();
  });
  orderForm.on("submit", (e) => {
    let success = true;
    if (!tons.val() && success) {
      tons.trigger("focus");
      success = false;
    }
    if ((!phone.cleanVal() || phone.cleanVal().length !== 10) && success) {
      phone.trigger("focus");
      success = false;
    }
    if (selectAddress.length > 0 && !selectAddress.val() && success) {
      selectAddress.select2("open");
      success = false;
    }
    if (address.length > 0 && !address.val() && success) {
      address.trigger("focus");
      success = false;
    }
    if (success) {
      overlay.fadeIn(500);
      overlay.css("display", "flex");
    }
    e.preventDefault();
  });
  interface IHash {
    [details: string]: string | number | string[];
  }
  $("#confirm-order-btn").on("click", (e) => {
    const data : IHash = {
      qty: tons.val(),
      phone: phone.cleanVal().toString(),
      product: product.val(),
    };
    if (address.length > 0) {
      data["delivery_address"] = address.val();
    }
    if (selectAddress.length > 0) {
      data["delivery"] = selectAddress.val();
    }
    $.ajax({
      data,
      method: "POST",
      url: "/api/order/",
      dataType: "json",
    }).done((data) => {
      // window.dataLayer.push({
      //   event: "Ordered submitted",
      // });
      $(".order-id").text(data.id);
      overlay.css("display", "none");
      $(".thank").css("display", "flex");
    });
    e.preventDefault();
  });

  tons.on("change paste keyup", (e) => {
    order.qty = parseInt($(e.target).val().toString(), 10);
    calculate();
  });

  const calculate = () => {
    $(".order-tons").text(order.qty);
    $(".order-price").text(order.qty * order.price);
    let delivery = 0;
    if (selectAddress.length > 0) {
      $(".order-delivery").text(order.delivery_price);
      delivery = order.delivery_price;
    } else {
      $(".order-delivery").text("уточняйте");
    }
    const total = (order.qty * order.price) + delivery;
    if (total) {
      $(".order-total").text(total);
    }
  };
  calculate();
});
